/* eslint-disable @typescript-eslint/ban-types */
import { Error } from '../types';
import { Toaster } from '../utils/toast';

export function ApiCall<T>(promise: Promise<object>, onSuccess: (success: T) => void, onError: (error: Error) => void, toastApi?: Toaster): void {
    promise.then(
        (successResp) => {
            onSuccess((successResp as unknown) as T);
            toastApi?.success?.();
        },
        (errResp) => {
            onError(errResp as Error);
            toastApi?.error?.(errResp);
        }
    );
}
